exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-bandung-index-mdx": () => import("./../../../src/pages/blog/bandung/index.mdx" /* webpackChunkName: "component---src-pages-blog-bandung-index-mdx" */),
  "component---src-pages-blog-bangkok-index-mdx": () => import("./../../../src/pages/blog/bangkok/index.mdx" /* webpackChunkName: "component---src-pages-blog-bangkok-index-mdx" */),
  "component---src-pages-blog-chiang-mai-index-mdx": () => import("./../../../src/pages/blog/chiang-mai/index.mdx" /* webpackChunkName: "component---src-pages-blog-chiang-mai-index-mdx" */),
  "component---src-pages-blog-east-java-index-mdx": () => import("./../../../src/pages/blog/east-java/index.mdx" /* webpackChunkName: "component---src-pages-blog-east-java-index-mdx" */),
  "component---src-pages-blog-first-travel-blog-index-mdx": () => import("./../../../src/pages/blog/first-travel-blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-first-travel-blog-index-mdx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-khao-sok-index-mdx": () => import("./../../../src/pages/blog/khao-sok/index.mdx" /* webpackChunkName: "component---src-pages-blog-khao-sok-index-mdx" */),
  "component---src-pages-blog-khao-yai-index-mdx": () => import("./../../../src/pages/blog/khao-yai/index.mdx" /* webpackChunkName: "component---src-pages-blog-khao-yai-index-mdx" */),
  "component---src-pages-blog-kuala-lumpur-index-mdx": () => import("./../../../src/pages/blog/kuala-lumpur/index.mdx" /* webpackChunkName: "component---src-pages-blog-kuala-lumpur-index-mdx" */),
  "component---src-pages-blog-pai-index-mdx": () => import("./../../../src/pages/blog/pai/index.mdx" /* webpackChunkName: "component---src-pages-blog-pai-index-mdx" */),
  "component---src-pages-blog-phuket-index-mdx": () => import("./../../../src/pages/blog/phuket/index.mdx" /* webpackChunkName: "component---src-pages-blog-phuket-index-mdx" */),
  "component---src-pages-blog-roborock-filter-index-mdx": () => import("./../../../src/pages/blog/roborock-filter/index.mdx" /* webpackChunkName: "component---src-pages-blog-roborock-filter-index-mdx" */),
  "component---src-pages-blog-tasikmalaya-index-mdx": () => import("./../../../src/pages/blog/tasikmalaya/index.mdx" /* webpackChunkName: "component---src-pages-blog-tasikmalaya-index-mdx" */),
  "component---src-pages-blog-yogyakarta-index-mdx": () => import("./../../../src/pages/blog/yogyakarta/index.mdx" /* webpackChunkName: "component---src-pages-blog-yogyakarta-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

